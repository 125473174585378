import { useState } from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import "./App.css";

import logo0 from "./images/logo0.png";
import logo1 from "./images/logo1.png";
import logo2 from "./images/logo2.png";
import logo3 from "./images/logo3.png";
import logo4 from "./images/logo4.png";

import body0 from "./images/model0.png";
import body1 from "./images/model1.png";
import body2 from "./images/model2.png";
import body3 from "./images/model3.png";
import body4 from "./images/model4.png";
import body5 from "./images/model5.png";
import body6 from "./images/model6.png";
import body7 from "./images/model7.png";
import body8 from "./images/model8.png";
import body9 from "./images/model9.png";

import footer0 from "./images/0_footer.webp";
import footer2 from "./images/2_footer.webp";
import footer4 from "./images/4_footer.webp";
import footer5 from "./images/5_footer.webp";
import footer6 from "./images/6_footer.webp";
import footer7 from "./images/7_footer.webp";
import footer8 from "./images/8_footer.webp";
import footer9 from "./images/9_footer.webp";
import footer10 from "./images/10_footer.webp";
import footer11 from "./images/11_footer.webp";

import wlButton from "./images/y00tlist_apply.webp";
import clickme from "./images/clickme.webp";
import wPaper from "./images/ypaper.webp";
import follow from "./images/follow.webp";

import advisor from "./images/buttons/advisor.png";
import apply from "./images/buttons/apply0.png";
import followB from "./images/buttons/follow2.png";
import manifesto from "./images/buttons/manifesto2.png";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  position: relative;

  background-color: ${(props) =>
    props.color === 0
      ? "#979797"
      : props.color === 1
      ? "#b4daf7"
      : props.color === 2
      ? "#efebd4"
      : props.color === 3
      ? "#d5ffea"
      : props.color === 4
      ? "#cbeadf"
      : props.color === 5
      ? "#eceee6"
      : props.color === 6
      ? "#bdbdbd"
      : props.color === 7
      ? "#f6f6f6"
      : props.color === 8
      ? "#ffdde2"
      : "#ffe9e4"};

  .advisor {
    position: absolute;
    top: 0;
    left: 40px;
    margin: 0;
    cursor: pointer;

    img {
      max-height: 64px;
    }
  }

  .wl-button {
    position: absolute;
    top: 0;
    right: 40px;
    margin-top: 16px;
    cursor: pointer;

    img {
      max-height: 75px;
    }
  }

  .w-paper {
    margin: 16px;
    position: absolute;
    bottom: 0;
    left: 0;
    cursor: pointer;

    img {
      max-height: 75px;
    }
  }

  .follow {
    margin: 16px;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;

    img {
      max-height: 75px;
    }
  }

  @media (min-width: 1024px) {
    .advisor,
    .wl-button,
    .w-paper,
    .follow {
      margin: 40px;
    }

    .advisor {
      margin: 32px;
    }
  }
`;

const Header = styled.header`
  height: 33%;
  width: 83.333333%;
  margin-top: 80px;
  margin-left: 16px;
  margin-right: 16px;
  position: relative;

  @media (min-width: 768px) {
    height: 25%;
    margin-top: 48px;
  }

  @media (min-width: 1024px) {
    height: 30%;
  }

  img {
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: medium none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;

const Body = styled.div`
  justify-content: center;
  display: flex;
  padding-right: 40px;
  padding-left: 40px;
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid black;

  .click-me {
    position: absolute;
    top: 55%;
    left: 0;

    img {
      height: 58px;
    }
  }
`;

const Footer = styled.footer`
  width: 83.333333%;
  height: 100px;
  margin-bottom: 80px;
  margin-left: 16px;
  margin-right: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    margin-top: 16px;
    font-family: Anton;
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    color: #f7ff92;
    text-transform: uppercase;
    color: ${(props) =>
      props.color === 0
        ? "#f7ff92"
        : props.color === 1
        ? "#737a77"
        : props.color === 2
        ? "#010101"
        : props.color === 3
        ? "#01fa3b"
        : props.color === 4
        ? "#fe1e41"
        : props.color === 5
        ? "#717876"
        : props.color === 6
        ? "#f7ff92"
        : props.color === 7
        ? "#396da4"
        : props.color === 8
        ? "#fe1e41"
        : "#492c97"};
  }

  @media (min-width: 768px) {
    width: 66.666667%;
    height: 25%;
    margin-bottom: 40px;

    h2 {
      font-size: 32px;
      margin-top: 0;
    }
  }

  @media (min-width: 1024px) {
    width: 50%;
    h2 {
      font-size: 56px;
    }
  }

  img {
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: medium none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;

const App = () => {
  const [currentColor, setCurrentColor] = useState(0);

  const headerImg =
    currentColor === 0
      ? logo1
      : currentColor === 1
      ? logo1
      : currentColor === 2
      ? logo2
      : currentColor === 3
      ? logo3
      : currentColor === 4
      ? logo4
      : currentColor === 5
      ? logo0
      : currentColor === 6
      ? logo1
      : currentColor === 7
      ? logo2
      : currentColor === 8
      ? logo3
      : logo4;

  const bodyImg =
    currentColor === 0
      ? body0
      : currentColor === 1
      ? body1
      : currentColor === 2
      ? body2
      : currentColor === 3
      ? body3
      : currentColor === 4
      ? body4
      : currentColor === 5
      ? body5
      : currentColor === 6
      ? body6
      : currentColor === 7
      ? body7
      : currentColor === 8
      ? body8
      : body9;

  const footerImg =
    currentColor === 0
      ? footer6
      : currentColor === 1
      ? footer5
      : currentColor === 2
      ? footer2
      : currentColor === 3
      ? footer8
      : currentColor === 4
      ? footer4
      : currentColor === 5
      ? footer5
      : currentColor === 6
      ? footer6
      : currentColor === 7
      ? footer7
      : currentColor === 8
      ? footer8
      : footer9;

  const changeColor = () => {
    console.log("clicked");
    if (currentColor < 9) {
      setCurrentColor((prev) => prev + 1);
      console.log("less than 9");
    } else {
      setCurrentColor(0);
      console.log("equal 9");
    }
  };

  return (
    <Container color={currentColor}>
      <Header>
        <img src={headerImg} alt="" />
      </Header>
      <Body onClick={changeColor}>
        <img src={bodyImg} alt="" />
        <div className="click-me">
          <img src={clickme} alt="" />
        </div>
      </Body>
      <Footer color={currentColor}>
        {/* <img src={footerImg} alt="" /> */}
        <h2>We are here to change the meta.</h2>
      </Footer>
      <div className="advisor">
        <a
          href="https://forms.gle/uAvAnacqywMPZ4sm9"
          rel="noreferrer noopener"
          target="_blank"
        >
          <img src={advisor} alt="" />
        </a>
      </div>
      <div
        className="wl-button"
        data-tip="coming soon"
        data-place="bottom"
        data-delay-hide="1"
      >
        {/* <a
          href="https://forms.gle/uAvAnacqywMPZ4sm9"
          rel="noreferrer noopener"
          target="_blank"
        > */}
        <img src={apply} alt="" />
        <ReactTooltip />
        {/* </a> */}
      </div>
      <div className="w-paper">
        <a
          href="https://twitter.com/y00ppiesNFT"
          rel="noreferrer noopener"
          target="_blank"
        >
          <img src={manifesto} alt="" />
        </a>
      </div>
      <div className="follow">
        <a
          href="https://twitter.com/y00ppiesNFT"
          rel="noreferrer noopener"
          target="_blank"
        >
          <img src={followB} alt="" />
        </a>
      </div>
    </Container>
  );
};

export default App;
